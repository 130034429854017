const USER_CONFIG_KEY = "userConfig";

export const UserConfigs = {
    testKits: "testKits",
    username: "username",
    accessToken: "accessToken",
    accessTokenExpireOn: "accessTokenExpireOn",
    oauth2: "oauth2",
    loggedInTime: "loggedInTime",
    selectedAquarium: "selectedAquarium",
    aquariums: "aquariums",
    selectedParamType: "selectedParamType",
    isDemo: "isDemo",
    limitByPage: "limitByPage",
    setDays: "setDays",
};

/* API endpoints
Local:  http://localhost:8000
GCP:    https://api.logreef.ostamand.com
*/

const userConfigDefaults = {
    testKits: {},
    username: undefined,
    accessToken: undefined,
    accessTokenExpireOn: undefined,
    loggedInTime: undefined,
    oauth: undefined,
    selectedAquarium: "Default",
    selectedParamType: undefined,
    aquariums: undefined,
    isDemo: false,
    limitByPage: 10,
    setDays: undefined,
};

let instance;

class UserConfig {
    static data;

    constructor() {
        if (instance) {
            throw new Error("Can create only one instance of UserConfig");
        }
        this.load();
        instance = this;
    }

    get(userConfig) {
        if (!Object.keys(UserConfigs).includes(userConfig)) return;

        if (Object.keys(this.data).includes(userConfig)) {
            return this.data[userConfig];
        } else if (Object.keys(userConfigDefaults).includes(userConfig)) {
            // return default value and save from now on as user config
            this.data[userConfig] = userConfigDefaults[userConfig];
            return this.data[userConfig];
        }
    }

    getTestKit(paramName) {
        return this.data["testKits"][paramName];
    }

    setTestKit(paramName, testKitName) {
        this.data["testKits"][paramName] = testKitName;
        this.save();
    }

    getAquariums() {
        if (!("aquariums" in this.data) || !this.data["aquariums"]) {
            // need to get list of aquariums from API
            return undefined;
        }
        const aquariums = this.data["aquariums"].split(",");
        return aquariums;
    }

    setAquariums(aquariums) {
        let aquariumData = "";
        aquariums.forEach((aquarium) => {
            aquariumData += aquarium + ",";
        });
        this.data["aquariums"] = aquariumData.substring(
            0,
            aquariumData.length - 1
        );
        this.save();
    }

    isDemo() {
        return this.data["isDemo"];
    }

    setMany(userConfigs, values) {
        userConfigs.forEach((userConfig, i) => {
            this.data[userConfig] = values[i];
        });
        this.save();
    }

    set(userConfig, value) {
        this.data[userConfig] = value;
        this.save();
    }

    clear(...userConfigs) {
        userConfigs.forEach((userConfig) => {
            this.data[userConfig] = userConfigDefaults[userConfig];
        });
        this.save();
    }

    load() {
        const configData = localStorage.getItem(USER_CONFIG_KEY);
        if (configData) {
            this.data = JSON.parse(configData);
        } else {
            this.data = userConfigDefaults; // apply default configs if none
        }
    }

    save() {
        if (!this.data) return;
        localStorage.setItem(USER_CONFIG_KEY, JSON.stringify(this.data));
    }
}

export const isMobile = () => {
    const minWidth = 768;
    return window.innerWidth < minWidth || screen.width < minWidth;
};

export const userConfig = new UserConfig();
