import { userConfig, UserConfigs } from "../common/user-config";
import { getAccessToken } from "../helpers/auth";
import { logout } from "../helpers/auth";

const CONTENT_TYPE_JSON = "application/json";

export class LogReefAPI {
    constructor(url) {
        this.url = url;
    }

    async _sendRequest(
        endpoint,
        method,
        authenticate = true,
        body = undefined,
        contentType = ""
    ) {
        const headers = new Headers();
        const options = {
            method,
            headers: headers,
        };
        const oauth2 = userConfig.get(UserConfigs.oauth2);
        if (oauth2) {
            headers.append("oauth2", "true");
        }
        if (authenticate) {
            const accessToken = getAccessToken();
            headers.append("Authorization", "Bearer " + accessToken);
        }
        if (body) {
            options["body"] = body;
        }
        if (contentType) {
            headers.append("Content-Type", contentType);
        }
        let status;
        try {
            const response = await fetch(this.url + endpoint, options);
            status = response.status;
            let data = await response.json();
            // force new login
            if (status === 401) {
                logout();
            }
            return [status, data];
        } catch (error) {
            console.log(error);
            return [status, null];
        }
    }

    async me() {
        return this._sendRequest("/users/me", "GET");
    }

    async login(username, password) {
        const formData = new FormData();
        formData.append("username", username);
        formData.append("password", password);
        return this._sendRequest("/login", "POST", false, formData);
    }

    async summary(aquarium, paramType = undefined) {
        let endpoint = `/summary/?aquarium=${aquarium}`;
        if (paramType) {
            endpoint += `?type=${paramType.toLowerCase()}`;
        }
        return this._sendRequest(endpoint, "GET");
    }

    async getTestkitsByType(paramType) {
        const endpoint = `/testkits/?type=${paramType}`;
        return this._sendRequest(endpoint, "GET");
    }

    async getParamByType(
        aquarium,
        paramType = undefined,
        limit = undefined,
        offset = undefined,
        days = undefined
    ) {
        // example: /params/?type=ph&limit=2&offset=0
        let endpoint = `/params/?aquarium=${aquarium}`;
        if (paramType) {
            endpoint += `&type=${paramType}`;
        }
        if (limit) {
            endpoint += `&limit=${limit}`;
        }
        if (offset) {
            endpoint += `&offset=${offset}`;
        }
        if (days) {
            endpoint += `&days=${days}`;
        }
        return this._sendRequest(endpoint);
    }

    async getParamsCountByType(aquarium, paramType = undefined) {
        let endpoint = `/params/count?aquarium=${aquarium}`;
        if (paramType) {
            endpoint += `&type=${paramType}`;
        }
        return this._sendRequest(endpoint);
    }

    async getAquariums() {
        return this._sendRequest("/aquariums", "GET");
    }

    async updateByAquariumID(id, updates) {
        return this._sendRequest(
            `/aquariums/${id}`,
            "PUT",
            true,
            JSON.stringify(updates),
            CONTENT_TYPE_JSON
        );
    }

    async saveParam(body, commit = true) {
        // test_kit_name, param_type_name, value, aquarium
        let url = "/params";
        if (!commit) {
            url += "?commit=false";
        }
        return this._sendRequest(
            url,
            "POST",
            true,
            JSON.stringify(body),
            CONTENT_TYPE_JSON
        );
    }

    async saveAquarium(body) {
        const url = "/aquariums";
        return this._sendRequest(
            url,
            "POST",
            true,
            JSON.stringify(body),
            CONTENT_TYPE_JSON
        );
    }

    async deleteParam(id) {
        return this._sendRequest(`/params/${id}`, "DELETE", true);
    }

    async deleteAquarium(id) {
        return this._sendRequest(`/aquariums/${id}`, "DELETE", true);
    }

    async ping() {
        return this._sendRequest("/", "GET", false);
    }

    async getByParamID(id) {
        return this._sendRequest(`/params/${id}`, "GET", true);
    }

    async updateByParamID(id, updates) {
        return this._sendRequest(
            `/params/${id}`,
            "PUT",
            true,
            JSON.stringify(updates),
            CONTENT_TYPE_JSON
        );
    }

    async register(
        username,
        password,
        fullname = undefined,
        email = undefined
    ) {
        const body = {
            username,
            password,
            fullname,
            email,
        };
        return this._sendRequest(
            "/register",
            "POST",
            false,
            JSON.stringify(body),
            CONTENT_TYPE_JSON
        );
    }

    async checkRegister(username, email) {
        const endpoint = `/register?username=${username}&email=${email}`;
        return this._sendRequest(endpoint, "GET", false);
    }

    async registerWithAccessToken(accessToken) {
        const body = {
            accessToken,
        };
        return this._sendRequest(
            "/register",
            "POST",
            false,
            JSON.stringify(body),
            CONTENT_TYPE_JSON
        );
    }

    async confirmEmail(token) {
        return this._sendRequest(`/confirm-email?token=${token}`, "GET", false);
    }

    async sendMessage(values) {
        return this._sendRequest(
            "/messages",
            "POST",
            false,
            JSON.stringify(values),
            CONTENT_TYPE_JSON
        );
    }
}
