const warmupKey = "warmup";
if (!sessionStorage.getItem("warmup")) {
    fetch("https://logreef-api-585499903514.us-east4.run.app").then(
        (response) => {
            if (response.status === 200) {
                sessionStorage.setItem("warmup", response.status);
            }
        }
    );
}
