import dayjs from "dayjs";
import { userConfig, UserConfigs } from "../common/user-config";
import { getSession } from "./supabase";

export async function updateAuth() {
    // access token thru supabase
    if (userConfig.get(UserConfigs.oauth2)) {
        const sessionData = await getSession();
        if (sessionData?.data?.session) {
            // update local storage per current session data
            userConfig.set(
                UserConfigs.accessToken,
                sessionData.data.session.access_token
            );
            return true;
        } else {
            logout();
            return false;
        }
    }

    // access token thru api
    const token = userConfig.get(UserConfigs.accessToken);
    const username = userConfig.get(UserConfigs.username);
    const expires_on = userConfig.get(UserConfigs.accessTokenExpireOn);

    // check if token is set
    if (!token || !username) {
        return false;
    }
    // check if expires data is valid

    if (dayjs().isAfter(dayjs(expires_on))) {
        // token not valid anymore
        logout();
        return false;
    }
    return true;
}

const refreshAquariums = async (api) => {
    // refresh available aquariums for user
    const [status, aquariumsData] = await api.getAquariums();
    if (status !== 200) return false;

    const aquariums = aquariumsData.map((aquarium) => aquarium.name);
    userConfig.setAquariums(aquariums);

    const selectedAquarium = userConfig.get(UserConfigs.selectedAquarium);
    if (!aquariums.includes(selectedAquarium)) {
        userConfig.set(UserConfigs.selectedAquarium, "");
    }
    if (aquariums.length === 1) {
        userConfig.set(UserConfigs.selectedAquarium, aquariums[0]);
    }
    return true;
};

export async function login(api, email, password) {
    let [status, dataUser] = await api.login(email, password);
    if (status !== 200) return [status, dataUser];

    // update user configs for password login
    userConfig.setMany(
        [
            UserConfigs.accessToken,
            UserConfigs.accessTokenExpireOn,
            UserConfigs.username,
            UserConfigs.isDemo,
            UserConfigs.loggedInTime,
            UserConfigs.oauth2,
        ],
        [
            dataUser.access_token,
            dayjs(dataUser.expires_on).format(),
            dataUser.username,
            dataUser.is_demo,
            dayjs().format(),
            false,
        ]
    );

    await refreshAquariums(api);

    return [status, dataUser];
}

export async function loginWithOAuth2(api, access_token) {
    userConfig.setMany(
        [UserConfigs.accessToken, UserConfigs.oauth2],
        [access_token, true]
    );

    const [status, user] = await api.me();

    if (status !== 200) {
        return false;
    }

    // update user configs for password login
    userConfig.setMany(
        [UserConfigs.username, UserConfigs.isDemo], // can't be demo thru oauth2
        [user.username, false]
    );

    await refreshAquariums(api);

    return true;
}

export function logout() {
    userConfig.clear(
        UserConfigs.accessToken,
        UserConfigs.accessTokenExpireOn,
        UserConfigs.username,
        UserConfigs.isDemo,
        UserConfigs.oauth2
    );
}

export function getAccessToken() {
    return userConfig.get(UserConfigs.accessToken);
}

export function getUsername() {
    return userConfig.get(UserConfigs.username);
}

export function timeSinceLogin() {
    let loginTime = userConfig.get(UserConfigs.loggedInTime);
    if (!loginTime) return;
    const diffSecs = dayjs().diff(dayjs(loginTime), "seconds");
    return diffSecs;
}
