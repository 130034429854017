import { createClient } from "@supabase/supabase-js";

const supabaseConfig = {
    projectURL: "https://felvvuwaligvhzztfsvi.supabase.co",
    apiKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZlbHZ2dXdhbGlndmh6enRmc3ZpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzQ3NDI1MjUsImV4cCI6MjA1MDMxODUyNX0.ADgvSBG479-7-LL9k3BvJKZKerDjuMdiO2OnPaeOEc4",
};

export const supabase = createClient(
    supabaseConfig.projectURL,
    supabaseConfig.apiKey
);

export const signupEmailPassword = async (username, email, password) => {
    const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
            data: {
                display_name: username,
            },
        },
    });
    return { data, error };
};

export const signInWithGoogle = async (redirectTo) => {
    try {
        const { error } = await supabase.auth.signInWithOAuth({
            provider: "google",
            options: {
                redirectTo: window.location.origin + redirectTo,
            },
        });
        if (error) throw error;
    } catch (error) {
        console.error("Error signing in with Google:", error.message);
    }
};

export const getSession = async () => {
    const data = await supabase.auth.getSession();
    return data;
};
