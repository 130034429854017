import Configs from "../configs";

if (Configs.env === "prd") {
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-029DCCFT7N";
    script.async = true;
    document.head.prepend(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
        dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "G-029DCCFT7N");
}
